import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { Location } from "@reach/router";
import { getUser, disableUser, enableUser } from "../../functions/admin/users";

const EditUser = () => {
  const dispatch = useDispatch();
  const [loading, update_loading] = useState(true);
  const [query_string, update_query_string] = useState("");

  const [user_details, update_user_details] = useState(null);

  useEffect(() => {
    if (!loading) {
      getUserInfo();
    }
  }, [loading]);

  function fetchUserDetails(search) {
    update_query_string(search);

    // listCognitoUsers("get_user", { Username: search.id });
    // listCognitoUsers("list_groups_for_user", { Username: search.id });
    update_loading(false);
  }

  function returnForm(location, navigate) {
    if (loading) {
      const search = location.search ? queryString.parse(location.search) : {};
      fetchUserDetails(search);
      return <div>Loading...</div>;
    }

    return (
      <div>
        {/* <Edit location={location} navigate={navigate} search={search} /> */}
        <div>User ID: {query_string.id}</div>
        <div>{JSON.stringify(query_string.id)}</div>
        {/* <div>{returnUserDetails()}</div>
        <div>{returnUserGroups()}</div> */}
      </div>
    );
  }

  async function getUserInfo() {
    console.log(typeof query_string.id);
    const response = await getUser(query_string.id);
    console.log("response");
    console.log(response);
    update_user_details(response);
  }

  return (
    <div>
      <div>
        <h1 onClick={() => getUserInfo()}>Edit User</h1>
      </div>
      <Location>
        {({ location, navigate }) => returnForm(location, navigate)}
      </Location>
    </div>
  );
};

export default EditUser;
